import { useState } from 'react'
import Rule from './Rule'

const CharacterRules = ({ character }) => {
    const [ruleIndex, setRuleIndex] = useState(-1)

    return (
        <>
            {ruleIndex >= 0 &&
                <Rule character={character} ruleIndex={ruleIndex} setRuleIndex={setRuleIndex} />}
            {ruleIndex < 0 &&
                <div className="character-rules">
                    <h1 className="character-info-header">{character.name}</h1>
                    <p className="character-info">{character.bio}</p>
                    <ul className="rule-list">
                        {character.rules.map((rule, index) => {
                            return (
                                <li className="rule-list-item" key={index}>
                                    <h2>Rule #{index + 1}</h2>
                                    <button className="rule-button" onClick={() => setRuleIndex(index)}>{rule.trigger}</button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            }
        </>
    )
}

export default CharacterRules