import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import './index.css';
import './routes/mft/styles/index.css';
import Root from './routes/root/Root';
import BucketList from './routes/bucketlist/BucketList'
import MFT from './routes/mft/routes/MFT';
import Error from './Error';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />
  },
  {
    path: "/bucketlist",
    element: <BucketList />,
    errorElement: <Error />
  },
  {
    path: "/mash",
    element: <MFT />,
    errorElement: <Error />,
    children: [
      {
        path: ":pw",
        element: <MFT />,
      },
      {
        path: ":pw/start",
        element: <MFT />,
      },
      {
        path: ":pw/solve",
        element: <MFT />,
      },
    ],
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
