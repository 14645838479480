const CharacterClues = ({ character, setSuspect }) => {

    return (
        <div className="character-clues">
            <h1 className="character-info-header">{character.name}</h1>
            <p className="character-info">{character.bio}</p>
            <div className="clue-board">
                {character.clues.map((clue, index) => {
                    return (
                        <div className="clue" key={index}>
                            <h5 className="clue-heading">CLUE #{index + 1}</h5>
                            <p>{clue}</p>
                        </div>
                    )
                })}
                <button className="back-button" onClick={() => setSuspect(false)}>BACK</button>
            </div>
        </div>
    )
}

export default CharacterClues