import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CHARACTER_DATA } from '../data/characters'
import Login from '../components/Login'
import CharacterBackground from '../components/CharacterBackground'
import CharacterRules from '../components/CharacterRules'
import SuspectList from '../components/SuspectList';


function MFT() {
    let { pw } = useParams();
    const url = window.location.pathname
    const slug = url.substring(url.lastIndexOf('/') + 1)
    const partyStarted = slug === 'start';
    const murderHappened = slug === 'solve';
    const [loggedIn, setLoggedIn] = useState(CHARACTER_DATA.some(c => c.pw === pw))
    if (pw === 'start' || pw === 'solve') pw = ''
    const [password, setPassword] = useState(pw || '')

    let character = {}
    const loginHandler = (event) => {
        event.preventDefault();
        for (let ch of CHARACTER_DATA) {
            if (ch.pw === password) {
                character = ch
                setLoggedIn(true)
            }
        }
    }
    let sendCharacter = {}
    if (loggedIn && password !== '') {
        character = CHARACTER_DATA.find(ch => ch.pw === password)
        if (!partyStarted) sendCharacter = CHARACTER_DATA.find(c => c.pw === character.message.to)
    }


    return (
        <div className='app'>
            {!loggedIn || password === '' ?
                <Login loginHandler={loginHandler} password={password} setPassword={setPassword} />
                : !partyStarted && !murderHappened ?
                    <CharacterBackground character={character} sendCharacter={sendCharacter} />
                    : !murderHappened ?
                        <CharacterRules character={character} />
                        : <SuspectList character={character} />
            }
        </div >
    );
}

export default MFT;
