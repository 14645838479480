import { useState } from 'react'
import CharacterClues from './CharacterClues'
import { CHARACTER_DATA } from '../data/characters'

const SuspectList = ({ character }) => {
    const [suspect, setSuspect] = useState(false);

    return (
        <div>
            {suspect ?
                <CharacterClues character={suspect} setSuspect={setSuspect} />
                :
                <div className='suspect-list'>
                    <h1 className='suspect-header'>Suspects:</h1>
                    {CHARACTER_DATA.map((ch) => {
                        if (ch.pw !== character.pw && ch.clues.length > 0) {
                            return (
                                <button className='suspect-button' key={ch.pw} onClick={() => setSuspect(ch)}>
                                    <h1 className='suspect-name-header'>{ch.name}</h1>
                                    <p className='suspect-bio'>{ch.bio}</p>
                                </button>
                            )
                        }
                        return null
                    })}
                </div>
            }
        </div>
    )
}

export default SuspectList