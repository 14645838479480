const Rule = ({ character, setRuleIndex, ruleIndex }) => {
    return (
        <div className="rule">
            <h3 className="rule-trigger">{character.rules[ruleIndex].trigger}</h3>
            {
                character.rules[ruleIndex].link ?
                    <a className="link-rule" href={character.rules[ruleIndex].link} target="_blank" rel="noreferrer">{character.rules[ruleIndex].action}</a>
                    : <h1>{character.rules[ruleIndex].action}</h1>
            }
            <button className="back-button" onClick={() => setRuleIndex(-1)}> &lt; RULE #{ruleIndex + 1}</button>
        </div>
    )
}


export default Rule