import { formatPhoneNumber } from "../utils"
const CharacterBackground = ({ character, sendCharacter }) => {

    const phoneNumber = formatPhoneNumber(sendCharacter.pw)
    return (
        <div className="character-background">
            <h1 className="character-info-header">{character.name}</h1>
            <p className="character-info">{character.bio}</p>
            <h4 className="character-background-header">Background:</h4>
            <p className="character-background-content">{character.background}</p>
            <h4 className="character-action-header">Action:</h4>
            <div>Message {sendCharacter.name} ({phoneNumber}) {character.message.content}</div>
            <div className="character-background-send-container">
                <a className="character-background-send" href={`sms:/${phoneNumber}&body=Hey ${sendCharacter.name}, it's ${character.name}...`}>SEND</a>
            </div>
        </div>
    )
}

export default CharacterBackground