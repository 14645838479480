import './BucketList.css'
import HARDCODED_BUCKET_LIST from '../../data/bucketlist'

const BucketList = () => {
    return (
        <div className='bucketList'>
            <ol>
                {HARDCODED_BUCKET_LIST.map(item => <li className='item'>{item}</li>)}
            </ol>
        </div>
    )
}
export default BucketList