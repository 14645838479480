const Login = ({ loginHandler, password, setPassword }) => {
    return (
        <div className="auth">
            <video
                className="welcome-video"
                controls="controls"
                src='https://murderfuntime.s3.us-west-2.amazonaws.com/mayhem-at-the-monster-mash.mov?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEgaCXVzLXdlc3QtMSJHMEUCIEU2QJvI30Fck9w%2FBYK6ebilbshfDaxegXgRCZ2H5hM%2FAiEA9ahy3hv2W%2BWrvoKcykBFpTesWwKTUC3iVPYNSG1B7yAq%2BwIIMRABGgwzNTgwNjgxNTYwMzUiDNwAGZ6ZvMaWvmtjJyrYAoJhxlRVyVTaxRfHNCzIiGTi0PkKvPTiogLJTLPB7GPs4HkatVuZz9R6j43xqyPy9oxvZPs5M8BInpSq%2B6tR3JeRNblbvwYAb%2FtNywO9FzlEuRGO0ftPMErLflVc2o%2BaiVDFCwZXxCW0cdT8s7Ul7fec78A9NVZdms9vgc3mMdFvUtxHDnvSrxe2%2FO8Fk7TXMXytNiFydC0ptUsF5N42nRiTufowS%2BVvFNfPgxrKyrCpWSeDIGkolSY6MeXU3dMyRuM22afAUxDlBsl5RGU2EO0vlKLIwn1ubjlFDwJ6mdeUagImb%2BMZpGnxFW52RE2W7nxzsJKqfe6I%2FneeBCJA4Q8wtbB8N0N8NwETZ09l6E85M1XwNryxtD3miqCvNFGfXe5htsgXRsJQ2I%2BSB27l1QyRIHzqsaavyNvN0x9HrVvFk1fKI4MqdKChKpg0MdPOKcMDUGrqChV6MLr175oGOrMCohMzfTsuDNhm8AMQIwNFVEew5LYOSAEw91r3%2BQPt%2B5gTtVzWMHD1V5%2F6p%2FBFPIixtChoLpIPczd2LnFhU1zNRTQ1%2FlsWOdLzSM4Yp35RR4N6PhLInanVC2%2FaTSADA0%2FZ0tOFVddKOI9tOFwuh6AZfweGfENKprmUWsc341rfnOteLe90W%2BNI0smczQVGyrxmI8Tiv2yyazr1%2BYSYkcurpzLI70W1NVtCwAo5SKtYi3RRZ4JiLJ86fK5MHr5R%2FbcdpBoCt9k0Z4nM8oeq04ZMQva4EGstmCTGK9wlYVIi5UeKT%2Fe5AR0zcDPNOJZ04bPX5eQLqvu4HuA1aT2lQXCantib%2BMDpRbwitpz%2FS%2Fd0U9bkboJX7QROrvZmcDtqACHqmRGebzo0YPf3DjUt%2BEXJSK9KWQ%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221028T191943Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIAVGXUGO2B6E5PNNOO%2F20221028%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Signature=b0337666a88936a145438d3a3f1f70d2d12de2117d8368e40b02f44e8069c5ae'
                type="video/mov"
            />
            <p className="video-credit">Video by Josh Lohn</p>
            <form className="auth-form" onSubmit={loginHandler}>
                <h3>PASSWORD:</h3>
                <input className="auth-input"
                    id="password"
                    name="password"
                    onChange={event => setPassword(event.target.value.trim())}
                    type="tel"
                    value={password}
                />
                <button className="auth-input-button">Submit</button>
            </form>
        </div>
    );
}

export default Login