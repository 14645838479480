import './Root.css';

function Root() {
    return (
        <div className='container'>
            <h1>Hello world!</h1>
            <h2>I'm Nate Lohn</h2>
            <h3>Here is where you can find me on the web:</h3>
            <div>
                <a className="link" href='https://github.com/natelohn'>GITHUB</a>
                <a className="link" href='https://www.linkedin.com/in/nate-lohn-658a17159/'>LINKEDIN</a>
                <a className="link" href='https://twitter.com/natelohn'>TWITTER</a>
                <a className="link" href='/bucketlist'>BUCKET LIST</a>
            </div>
            <p>If you'd like to contact me, you can reach me at:</p>
            <a href="mailto:lohndev@gmail.com">LOHNDEV@GMAIL.COM</a>
        </div>
    );
}



export default Root;
