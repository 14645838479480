export const CHARACTER_DATA = [
    {
        name: "Boner McGee",
        pw: "2143948147",
        bio: `The bad boy of the CK5 who NEVER shows up on time to practice.`,
        background: `Boner is a simple skeleton and only concerned with having a good time. Sure they are the world's greatest pumpkins player, but who gives a fuck! They are only about 3 things, spooky snacks, getting drunk, and getting laid. That's right! Horniest skeleton at the party here and ready to mash privites Oh! And you overheard Boris talking w/ the Witch about a spell while they were in bed and you were in the closet. You gotta tell your homie Grim Reaper!`,
        clues: [`Can't see in the dark`, `Doesn't like any conflict, but is not submissive.`, `Thinks Vampires and everyone that isn't strictly dead like them and Grim is soft.`, `Hates Dracula's music, but like, so does everyone… also showed Dracula the Death card.`],
        message: {
            to: `5083693029`,
            content: `telling them that you heard about a spell that may let them kill all the undead creatures at the Mash (finally!) - you hope they get it! Everyone being bonies would be SO SICK! `
        },
        rules: [{
            trigger: `When it comes time to practice with the CK5…`,
            action: `You show up a little late, but DUH… it's the Monster Mash! Take it easy team - lets have some fun! Proceed to keep suggesting the least helpful thing to the group that is also maybe just the best thing… 😎BONER MCGEE! 😎`
        },
        {
            trigger: `If you leave a conversation…`,
            action: `Announce "Time for the Bone Show to get go!" and yell something about how skeletons rule and fleshies drool as you exit. Uh oh, you run into several things as you leave because it is too dark in here… Bonies still rule tho…`
        },
        {
            trigger: `If someone mentions Dracula to you…`,
            action: `Yeah you hate his music, but so does everyone… you know what is cool... Tarot readings! Then you MUST give them a Tarot card reading. If it is Dracula, you must give the reading with The Witchess and you predict…. DEATH! (draw the death card!)`
        }]


    },
    {
        background: `As the former caretaker(and alive person) for the best undead club in all of Transylvania, “The Graveyard”, you took pride in your job.You showed up night and day to make sure the place was in order and above all SAFE! Despite doing an exceptional job, you did happen to have one death happen on your watch, your own.Dracula thought it would be funny to “prank” you with a knife in the back.After one year of undeath, you're finally ready to return for the Monster Mash.`,
        bio: "Former Graveyard caretaker, newest lead of the CK5, and died at last year's Monster Mash.",
        clues: ['Current lead singer & hates being upstaged', 'Was killed by Dracula', 'Has been haunting Dracula and his son.', 'Can\'t touch or pick up anything'],
        message: {
            to: `2143948147`,
            content: "asking them know to not upstage you with any solos on Friday night. Last year they stole the show and this year it is YOUR time to shine as the new lead singer of the Crypt Kicker 5 (CK5)"
        },
        name: "Ghostface Killguy",
        pw: "5083699501",
        rules: [
            {
                trigger: `If anyone asks how you died…`,
                action: `You must tell them the story of how Dracula stabbed you in the back last year at The Monster Mash as a joke (cause your name) and then tell them about your journey to being named the lead singer of CK5 after spending 9 months learning to sing.`
            },
            {
                trigger: `If anyone hands you anything…`,
                action: `Politely decline and tell them that since you are a ghost, you can't hold anything. If it is food or a drink, let them know you would be happy to take a bite/sip if they'll hold it steady.`
            },
            {
                trigger: `If anyone asks you how he learned to sing…`,
                action: `You must admit to spending several months haunting Dracula and his son and you picked it up there.`,
            }
        ]
    },
    {
        name: "Boris",
        pw: "3144350302",
        bio: `Mad scientist and monster agent, always digging in the graveyard for new talent`,
        background: `While you were working in the lab late one night, you finally found the perfect formula for getting your monster to come up with a dance for the Monster Mash! You also heard from your witchy lover about a spell…on the night of this year's Mash, whoever kills Dracula will be granted limitless instant DJ remix powers! You have just the Vampire in mind…Dracula's aspiring DJ son, Dräc! Time to overthrow the Devil to become THE monster music mogul!`,
        clues: [`Wants to eliminate the monster music competition`, `He could only sign Vito after Dracula dies.`, `Has been extra mean to Zombie since they started dating Dracula (stake in heart)`, `Would breach his contract with the Devil if he killed Dracula`],
        message: {
            to: `4156767150`,
            content: `making sure they can make it to the party and reminding them Dracula isn't aware of what you two discussed a few weeks back… Also see if they have released any new music lately and tell them you can't wait to sign them after Dracula dies! `
        },
        rules: [
            {
                trigger: `After Igor's announcement…`,
                action: `Hurry to the lab to “reset” your mashing monster master piece! Cackle and shout IT'S ALIVE! After the music stops, get The Witch to confirm that whoever kills Dracula gets “limitless instant DJ remix powers” and shout MONSTER MUSIC WILL BE MINE!`,
                link: `https://open.spotify.com/track/3qJ3FVny2I4bQmSTxp71S8?si=86f5aa12b1fc4265`
            },
            {
                trigger: `When you see the CK5 practicing their performance…`,
                action: `Tell them it must be SPOOKIER! And ask the Zombie to stab the stake through their heart! (the perfect alibi for the future murder weapon) … make sure to get the stake back before they go on though!`
            },
            {
                trigger: `Igor anounces 5 minutes 'til showtime...`,
                action: `Bring the stake to Vito to kill Dracula. Be sneaky! On your way, reduce suspicion by loudly saying, “I would never kill a vampire… it would breach my contract with the Devil”. Tell Vito that you will hit the lights AFTER the CK5 are done with the song and that is when it is time to strike!`
            }
        ]

    },
    {
        name: "Caretaker Dan",
        pw: "4017931331",
        bio: `AKA Dective Dan, the new “caretaker” and uncover P.I. extraordinaire, just don't tell Boris!`,
        background: `One month ago, you received a strange request from an unknown benefactor to investigate a hit put on none other than Dracula himself. While this case is highly unusual, you've managed to find a lead that brought you to Boris' laboratory in the Castle East, but security there was too spooky for you. However, with your P.I. skills, you found a job posing as the caretaker at Boris' nightclub “The Graveyard”. Time to go undercover… `,
        clues: [`Is not a real caretaker and tricked Boris into hiring him.`, `Knew of the Devil's plan to kill Dracula due to keen sluething`, `Knows about the spell??`, `Was hired by Zombo to protect Dracula`],
        message: {
            to: `8165047035`,
            content: `Asking if there are any cleaning “spells” they'd like you to complete before the big night. Igor is simple, and he may let something slip about what this mysterious spell you've heard whispers of lately might be… pry if you need to.`
        },
        rules: [
            {
                trigger: `Right after the monster rises and the party starts…`,
                action: `You notice Zombo the Zomb, the person who hired you. How can you tell? They don't call you Detective… I mean… Caretaker Dan for nothing. Ask them to fill you in on the hit.`
            },
            {
                trigger: `If you speak with Ghostface Killguy…`,
                action: `Do your best to convince them that you are an actual graveyard caretaker. REALLY go over the top with it. So much so that it is obvious you are not a real graveyard caretaker. WHOOPS!`
            },
            {
                trigger: `If you're near The Devil or Van Helsing…`,
                action: `Act casual and “clean” while you easedrop on their conversations. Drop sneaky subtle hints by saying things like “Did you hear the Devil put a hit on Dracula??” and “I heard there is a magic spell in place tonight”… see if you can catch 'em slippin…`
            }
        ]
    },
    {
        name: "Davey Jones",
        pw: "6026840106",
        bio: `Legendary seaman, semi-retired as The Devil's hitman battling severe social anxiety`,
        background: `After centuries at sea, your locker got a bit too crowded for your liking, and you've come ashore to follow your real dream: showbiz, baby. You'll get in however you can, even if it means cozying up to The Devil themself with a (cushy, remote) job leveraging your one marketable skill: killing people. The job has led you to your first Monster Mash, and meeting a new crowd has you spooked. You're huntin' for a lil' somethin' (wink nudge) to loosen you up...`,
        clues: [`Wants to keep their job as The Devil's hitman because it is remote`, `Wants to beat Van Helsing to Dracula's kill to prove himself & gain back some confidence to help him deal w/ his social anxiety`, `Took a new spooky drug from Old Greg so is kinda unpredictable`, `Thought Vito was Dracula due to the drugs`],
        message: {
            to: `2149262260`,
            content: `saying you heard they might know where you could get some…candy…to make the *mash* a little more *good* (excessive emojis because that's how people interact right?) `
        },
        rules: [
            {
                trigger: `If anyone offers you spooky drugs or drinks…`,
                action: `You respond with YARRRRRR until it is consumed (responsibly of course). After the third time this happens, you must find Vito (aka Dräc) and ask him to play you the Transyvania Twist ('cause you have mistaken him for Dracula)`
            },
            {
                trigger: `If you see the Devil and Grim Reaper talking…`,
                action: `Ask them if there is anyone at the party they would like you to wack off… I mean any jerks you'd like wacked… I mean… kill? If it goes well see if they'll let you stay working remote next year.`
            },
            {
                trigger: `If anyone mentions Van Helsing to you…`,
                action: `Let them know that you are NOT a fan. 1) They're a monster killer… your job. 2) They sometimes take on wetwork from the Devil… ALSO your job & 3) Ask to have some of their drink? Daddy needs to stay WET...`
            }
        ]

    },
    {
        name: "Dracula",
        pw: "8475256768",
        background: `Once the greatest monster artist aliv… around. Now, people see you as a “has-been” despite having recently released a new single on FM radio. Monster music life comes at you fast and hard, and you may have indulged a bit too much, but who can blame you! You are Dracula for Devil\'s sake! This year is a new low, though, since it is the first year Boris didn't ask you to play at the Monster Mash. To add insult to injury, your girlfriend Zombo's band (CK5) is head-lining.`,
        bio: `Former monster artist of the century! Of course that was 400 years ago now…`,
        clues: [],
        message: {
            to: `3144350302`,
            content: `asking why you are not at least opening for the Crypt Kicker 5. Sure it has been over 400 years since you released the hit single “The Transylvania Twist”, but people still LOVE that song… right?`
        },
        rules: [
            {
                trigger: `If someone mentions the mash…`,
                action: `You must respond with “What ever happened to the Transylvania Twist!?” and then proceed to complain about the current state of monster music and how it's all about TikTok and algorithms and no one has and REAL tallent anyone.`
            },
            {
                trigger: `If you see the Crypy Kicker 5 practicing…`,
                action: `Come up and ask them if they'd like any feedback. Regardless of their answer, do everything you can to get them to let you join. If they do, upstage them by taking the mic at your first opportunity.`
            },
            {
                trigger: `When Crypt Kicker 5 goes on…`,
                action: `Wait and listen through the first chorus before being overcome with jealousy. Start asking people around you “What ever happened to my Transylvania Twist!?” Slowly ask it louder and louder until you are yelling.`
            }
        ]
    },
    {
        background: `Another year, another Monster Mash full of undead souls you just WISH you could claim. Being around all those zombies, ghouls, and vampires is usually a struggle, but your boss (and monster music mogul) the Devil wants the whole crew there, so you must go. At least last year someone died… Who knows, maybe this year you'll be able to claim a soul you've been after for over 1000 years…Dracula himself! (He also owes you a ton of money)`,
        bio: `The dark daddy and the Devil's right hand is here for a good time and your soul.`,
        clues: ['Dracula has escaped him for 1000s of years and owes him lots of money', 'Wanted to take advantage of the spell to kill everyone there', 'Has plotted with the Devil to claim Dracula\'s soul', 'Has a small bladder and was in the bathroom'],
        message: {
            to: `5083699501`,
            content: `reminding them it's been almost one year since you claimed their soul and they became a ghost (what fond memories!) and you can't wait to hear them perform in the CK5.`
        },
        name: "Grim Reaper",
        pw: "7345029471",
        rules: [
            {
                trigger: `If you hear mention of a spell…`,
                action: `Tell the person who mentioned it that you will kill everyone they ever loved tomorrow unless they tell you everything they know about the spell RIGHT NOW! You need that info and you need it yesterday!`
            },
            {
                trigger: `If The Devil asks you to carry out their bidding…`,
                action: `Take The Devil aside and let them know that if Dracula isn't killed TONIGHT you will tell everyone that you two have been plotting to kill him. Sure it's bold, but you've been drinking GHOUL-AID BABY`
            },
            {
                trigger: `When the Crypt Kicker 5 goes on…`,
                action: `NATURE CALLS! You have never had to go to the bathroom so bad in your life so you must get in line/asks to cut. If you aren't in the bathroom by the time they go on you WILL pee your pants.`
            }
        ]

    },
    {
        name: "Igor",
        pw: "8165047035",
        bio: `Boris' assistant. Slow but sexy lady killer`,
        background: `As Boris' uber-dedicated assistant, you don't get much time to let your hair down over your hunchback, and you're AMPED to let loose at the Mash. What you lack in looks, you make up for with your skill on the chains, which have made you quite a hit with the ladies (particularly those with a…fiendish side). Boris has made you the emcee for the Mash, and you're not taking it lightly - you're here to serve up a night to remember..hopefully accident-free.`,
        clues: [`VERY Clumsy/Has accidentally killed people`, `Broke the stake that killed Dracula`, `Boris's current wetworks guy`, `Would do anything to learn the Graveyard Shift dance`],
        message: {
            to: `4156996607`,
            content: `aaa`
        },
        rules: [{
            trigger: `At some point in every conversation…`,
            action: `Ask if they know the Graveyard Shift dance. You'd give your left tit to learn it.`
        },
        {
            trigger: `When talking to someone who's holding any food`,
            action: `tell them to be careful with that!!! You once accidentally killed a guy with [food] and it wasn't pretty`
        },
        {
            trigger: `aaaa`,
            action: `aaaa`
        }]


    },
    {
        name: "Janush the Ghoul",
        pw: "2149262260",
        bio: `Monster junkie that's come from their humble aboad to get a JOLT from the electrode`,
        background: `It isn't easy being a Ghoul… very few people recognize you as a “real” monster and even more confuse you for a Zombie (gross). It was almost too much to bear, but that is when you found JOLTs… Boris got you hooked initially and you were oh so grateful for brief relief they gave you. You soon found you'd do ANYTHING for the next hit. That was until you found yourself in monster prison. Never again… after this year's Monster Mash of course.`,
        clues: [`Have lied about all of their monster achievements and Dracula knows about it.`, `Doesn't realize The Moster has lost his memory so must be out of it… probably the JOLTs`, `Told Frankenstein he would literally kill anyone including Dracula for another Jolt`, `Would do anything to keep from going back to monster prison.`],
        message: {
            to: `9178485313`,
            content: `reminding them to bring lots of JOLTS to the Mash this week. Be really enthusiastic about your desire for JOLTS and thank them for always providing. Also make a joke that for a lifetime supply of JOLTs you'd kill Dracula (lol!) – If only you could afford them 🙁`
        },
        rules: [{
            trigger: `If the Monster speaks with you…`,
            action: `you notice something is off, but can't put your finger on it. Ask them for a jolt. If they give it to you for free wait ~5 minutes and see if you can milk 'em for more. Repeat until you have at least 5 Jolts.`
        },
        {
            trigger: `If Dracula speaks with you…`,
            action: `Begin to brag about all of your monster achievements, lying as HARD as you can to impress him. Uh oh… Are these damn JOLTs making you paranoid or can he tell you're lying? Look at his eyes… he knows! Damn vamps…. Take Dracula aside and beg him not to tell anyone you've been lying before taking the biggest JOLT hit of the night.`
        },
        {
            trigger: `If anyone seems judgemental towards you…`,
            action: `Slowly pull out your JOLT, take a hit, and begin to regale them about your time in monster prison and how JOLT addiction is more serious than you think. It's not as strong as Ghoul-Aid, but still nothing to mess around with and best to stay away… if you can. End with offering them a hit of the JOLT.`
        }]

    },
    {
        name: "Jessica",
        bio: `Thought this was a costume party and dressed up as a pirate (new zombie in denial)`,
        background: `Last week you found a very mangy, but sweet stray cat that bit you when you went to pet it. Ever since then your skin has started to sag (rot?) and you've been craving BRAINS! Your friends think you're a zombie, but that can't be it. You're probably just dehydrated. Regardless, some guy named Boris invited you to a party on Friday because “all new undead are invited!”.. Must be a costume party! Time to rock your new pirate costume!`,
        clues: [`Very new to monster life - wild card!`, `Distaste for zombies despite being one`, `Knew "Detective Dan" somehow??`, `Had no idea Dracula was real before tonight`],
        message: {
            to: `4017931331`,
            content: `your old friend from highschool who always wanted to be a P.I. letting them know you're also going to the party and can't wait to see his BRAINS… I mean… costume… tehe`
        },
        pw: "5303071303",
        rules: [{
            trigger: `If people ask you how you became a zombie…`,
            action: `Laugh at their somewhat confusing question before letting them know you are clearly dressed as a pirate. Point out all the other zombies at the party and let them know they must be confused before asking what they dressed up as.`
        },
        {
            trigger: `If people ask you if you were bit by anything…`,
            action: `Explain to them the story of you and that mangy cat. Then ask why they everyone keeps asking that because a lot of people have been asking that lately?`
        },
        {
            trigger: `If you see Dracula…`,
            action: `Introduce yourself and ask them who they are. If they tell you Dracula, insist on them telling you who they REALLY are, not just who they're dressed up as since Dracula isn't real.`
        }]

    },
    {
        name: "Old Gregg",
        background: `You normally hang with human folk, but once a year let your seaweed down and get weird with some monsters at the mash. You've been going all out for this mother licker for over 17 years, each one more crazy than the last. How you gonna top it this year?? Who knows, but you have enough Bailey's and spooky drugs to choke The Blob and plan on giving them to everyone - maybe this year your one true love Dracula will notice you… maybe…`,
        bio: `The “scary” old drug dealer that lives by (in) the lake - you don't know what they got!`,
        pw: "9723580070",
        clues: [`Gets violent when he drinks too many Bailey's and has been giving everyone drugs (to forget??)`, `Is jealous everyone thinks Dracula is cooler than him and wants to make Dracula mad/jealous`, `Always goes ALL OUT for the Monster Mash and every year tries to top the crazy things he's done.`, `Was in love with Dracula`],
        message: {
            to: `6026840106`,
            content: `to get hyped for the FUCKIN MONSTER MASH and see if he'd like to buy any of your spooky drugs… you've got loads of 'em and that mutha licka has social anxiety… fuck this is gonna be a fun party.`
        },
        rules: [{
            trigger: `If someone refuses your Bailey's…`,
            action: `Ask them if they want to drink Bailey's out of a shoe? If they refuse again, shout “I'll slap you motherlicker!”`
        },
        {
            trigger: `If you hear people talking about the Monster Mash`,
            action: `Brag about all the crazy shit you did at the last ones, list three WILD things that happened, each more outrageous than the last.`
        },
        {
            trigger: `If Dracula is brought up in conversation…`,
            action: `You are confused by the emotions you feel. Let them know you're mad about all the attention Dracula gets. If they validate you, let them know it's actually because you are jealous of him. If the convo gets deep, tell them you're really in love with Dracula.`
        }]

    },
    {
        name: "The Devil",
        pw: "6506196412",
        bio: `Underlord, monster music mogul, and leader of the Coffin Bangers gang.`,
        background: `When it comes to monster music, there is not a deal you haven't at least had your tail in. You basically run Transylvania and will defend your fiendish empire at all costs… that is why when a little birdie told you that whoever kills Dracula on the night of the monster mash will be granted limitless instant DJ remix powers, you immediately called out a hit on him in hopes he dies before the party… you can't have that type of competition!`,
        clues: [`Knew about the spell`, `Has had it out for Dracula since he broke contract and is furious that Dracula let his song be played on FM radio for the living to hear.`, `Put a secret hit out on him that Van Helsing took up.`, `Sworn an oath to Vampire kind that she would never take them to hell`],
        message: {
            to: `2143168682`,
            content: `letting them know you have heard tell of the spell they cast after Dracula broke up with them at last year's Monster Mash… make sure they know that if knowledge of the spell gets out, there will be HELL to pay!`,
        },
        rules: [{
            trigger: `If someone mentions Dracula to you…`,
            action: `Tell them that you have had it out for Dracula since he broke contract and is furious that Dracula let his song be played on FM radio for the living to hear, but if anyone dare touches him other than you tonight they will experience eternal torment!! (his ass is yours!)`
        },
        {
            trigger: `If you speak with Dracula or Vito…`,
            action: `Let them know you will forever honor your oath to never bring a Vampire to hell, but they should watch their backs tonight as someone else might do you the favor… oh and FM Radio blows! AM RULES! All the monsters LOVE AM Radio! The living listen to FM - Gross!`
        },
        {
            trigger: `If you speak with Van Helsing or Davy Jones…`,
            action: `Ask them why they have waited so long to kill Dracula! They better make sure they kill him tonight! You MUST represent whoever gets the limitless instant DJ remix powers ...but not before Boris's CK5 go on! You have a feeling he'll ruin their show (muahaha!)`
        }]


    },
    {
        name: "The Monster",
        pw: "9178485313",
        bio: `Suddenly, to their surprise… they can't remember a thing and only want to MASH!!`,
        background: `Who am I? I don't remember anything... What is this place? Who are these people?? What is that… is that beat.. so fresh… it makes me want to… to… to… MASH!!!! MASH!! MASH!! MASH!!!! MASH!! MASH!!  YOU LIVE TO TEACH PEOPLE THIS NEW LIT DANCE YOU MADE UP!!!`,
        clues: [`Used to be Boris's wetwork guy/drug dealer`, `Has lapses in memory & can't remember anything before the night.`, `CK5's special guest and doesn't like being upstaged`, `Was created to do nothing but "The Mash" and right now and has NO TIME to beef with Dracula`],
        message: {
            to: `7036777754`,
            content: `... wait who is that?? You don't know but you know you want to teach them the MASH! Ask them! DONT take no for an answer! MASH MASH MASH!!`
        },
        rules: [{
            trigger: `After Igor's announcement…`,
            action: `Hurry to the lab, play this song, and plop down on the “Slab” - The first words you've ever heard… IT'S ALIVE! Has this happened before?? As the hook starts, begin to rise and surprise everyone with the most sick dance move you have coined THE MASH … wait where are we again?`,
            link: `https://open.spotify.com/track/0xxZY5C9xxij3D1HkzbnfC?si=ab65142ff8e640ca`
        },
        {
            trigger: `If someone mentions Dracula or the CK5 to you…`,
            action: `Ask "Who is that? Do they know The Mash? Where are they? Do they wanna dance RIGHT NOW?" You know this cool one called The Mash you wanna show them. Find them and ask to teach them the Mash… Wait have you done them this before?? You get confused sometimes.`
        },
        {
            trigger: `If the Ghoul asks you for a JOLT…`,
            action: `Look into your bag and give one to the Ghoul. After you have given them your last JOLT, you have an intense and brief moment of clarity and remember all of your years working as Boris's wetworks monster/dealer ...very little before that. For a small moment you don't feel like Mashing… Share your feelings and a JOLT with Janoush…`
        }]


    },
    {
        name: "The Mummy Man",
        pw: "5083693029",
        bio: `Crypt Kicker Five's lead pumpkin player, beloved for his habit of falling asleep mid-song`,
        background:
            `You've come to the Monster Mash with your Crypt Kicker Five bandmate and lover, Ghostface Killguy, although things haven't been quite the same between you since he was killed by Dracula and you were diagnosed with narcolepsy. But what better place to reignite the spark than the event where you met all those years ago? Nothing makes the heart race quite like a little jealousy, and you're here to flaunt your flirt till Ghostface remembers what they're missing.`,
        message: {
            to: `4043680897`,
            content: `asking if he knows where you can get some Ghoul-Aid for the big night`
        },
        clues: [`Hates Dracula's music (too much blood)`, `Is a narcolept and has frequent violent night terrors.`, `Dracula killed their lover the Ghost`, `NEVER Touches wood - it sticks to his paper. He is also deathly afraid of blood.`],
        rules: [{
            trigger: `When Igor tells you it's almost time to go on stage…`,
            action: `Gather the Crypt Kicker Five (Ghost, Zombie, Skeleton, Ghoul) to practice your performance of the Monster Mash. Give the band the pep-talk of your after-lifetime! Inspire those spooky spookers to rattle their bones! Dracula is a has-been! No one likes his music anyway… to much blood! We're the CK5 dammit!`
        },
        {
            trigger: `If anyone refers to you as “The Mummy Man”.... `,
            action: `Respond by first singing, “Oh do you know The Mummy Man, The Mummy Man, The Mummy Man, Oh do you know The Mummy Man who lives on sandy planes!” before responding to them normally as if you did not just sing that. If anyone sings along, you must fall asleep on the spot and wake to a night terror, yellng about all the BLOOD (You hate blood!)`
        },
        {
            trigger: `If you ever touch anything wooden…`,
            action: `Yell “OUCH!” - UGH! Wood is the worst. Vent to whoever is nearby… only gold for you! No fucking broke ass wood! Sticks to your banages and gives you splitters and shit. Wood sucks. Sand… now THATs some stuff right there I tell ya…`
        }]

    },
    {
        name: "The Thing",
        pw: "4156996607",
        bio: `Once the punching bag of the monster community, ruthlessly bullied by Dracula.`,
        background: `It was hard for you growing up. All the other cooler monsters often did not include you in their monster games. Dracula was the worst of them. That was years ago, but it has still left its mark. You almost weren't going to go to the Monster Mash, but you are a HUGE CK5 fan so you decided to take some monster lessons to level up your confidence… hope it works!`,
        clues: [`Found threatening letters written to Dracula in their pockets`, `Dracula knows that The Thing has been taking monster lessons The Thing and doesn't want anyone to know about it`, `Dracula used to make fun of the Thing`, `Letters were from Therapy/no longer cares what Dracula thinks`],
        message: {
            to: `9723580070`,
            content: `letting them know you're excited about them being there and you will (yet again) really need their support due to the emotional turmoil being around Dracula causes you… they also do a lot of drugs, so you might need to remind them about the bullying.`
        },
        rules: [{
            trigger: `If Dracula tries to speak with you…`,
            action: `YOUR TIME TO SHINE! Scare that fucker! Uh oh… you may not actually be ready for this. If fact, you become completely paralyzed before explaining to them that it was something you've been working on in Monster lessons and ask them to please not tell anyone!`
        },
        {
            trigger: `If anyone laughs at you…`,
            action: `Find a piece of paper and write down your monster asperation and affirmations just like you've been practicing. If it is Dracula, make sure you write extra long and speak it out loud so everyone around you can hear.`
        },
        {
            trigger: `If you can muster the courage…`,
            action: `Drop one of your letters near Dracula so they notice… if they don't notice or care, stop whatever they (and everyone else around) are doing and MAKE them listen. Redemption and reclamation starts with bravery. If you need more courage recite your monster mantra out loud. Make sure to let them know you DON'T CARE what Dracula thinks anymore.`
        }]

    },
    {
        name: `Van Helsing`,
        pw: `4155336938`,
        bio: `Lifelong monster hunter, cousin to the Wolfman, and “The #1 monster music fan”`,
        background: `After Dracula murdered your family, you devoted your life to killing monsters, but after 30+ years you respect them - especially their monster music. You wouldn't dare tell anyone, but you have dreams of becoming the next big monster star! That is why you took on a contract from THE music mogul, The Devil, to kill Dracula. You've tracked down the Wolfman and forced them to take you to Dracula or die!`,
        clues: [`Dracula killed his whole family`, `Not really the Wolfman's cousin, but forced them to invite him on threat of death.`, `Has detailed plans to kill Dracula and took on the Devil's hit`, `Has actually changed since becoming a werewolf & now all they want is to be the next big monster artist, despite not being very good`],
        message: {
            to: `6506196412`,
            content: `letting them know you are SO CLOSE to completing your contract on Dracula and see if once it is done they will FINALLY let you audition to be the next big monster artist! (not likely give you aren't a Monster, but a monster hunter can dream!`
        },
        rules: [{
            trigger: `If anyone refers to you as the Wolfman's cousin…`,
            action: `You are overwhelmed by all the monster stars here and forget your ruse with the Wolfman for a moment. Acting confused, you nearly letting it slip that you aren't related before doubling down and finding Wolfman and intimidating them into to confirming you ARE cousins.`
        },
        {
            trigger: `If Dracula or his son try and speak with you…`,
            action: `You are overcome with rage due to the death of your family and start listing all 8 of their names. Afterward, let them know Dracula's days are numbered and slowly move your fingers across your neck insinuating you plan to kill them.`
        },
        {
            trigger: `If anyone says it is impossible to kill vampires…`,
            action: `Go into graphic detail about all the vampires you have killed and let them know exactly how you would kill Dracula - we're not talking stake in the heart or something boring like that, we're talking Broadway quality violence - who knows, a kill like that might get you noticed by the monster community.`
        }]

    },
    {
        name: "Vito (aka Dräc)",
        pw: "4156767150",
        background: `Two weeks ago you got a nefarious request from Boris asking you to kill your own father Dracula on the night of the 666th Monster Mash. In return, you will be granted limitless instant DJ remix powers! You were conflicted at first, but your Dad blows and has had his time. If this works, you might be able to redeem the Dracula brand before your father ruins it for both of you. Just make sure you don't tell anyone or they may claim the powers for themselves…`,
        bio: "Dracula's son, influencer, budding DJ, and heir to the Dracula entertainment empire.",
        clues: [
            "Secretly Hates his Dad (usually stays w/ Mom, but she is on a cruise in Lock Ness)",
            "Wants to use the name \"Dracula\" - currently going by \"Dräc\", but it isn't really working. Can only use the name if Dracula is dead.",
            "Saved Dracula from Van Helsing by getting Wolfman to attack him / turn him into a werewolf... so Van Helsing wouldn't get the powers of the spell?",
            "Boris told him about the spell and wants him to use the spell to get instant DJ remix powers and it is his hearts ONLY wish."
        ],
        message: {
            to: '8475256768',
            content: "your excuse for needing to be at the Monster Mash. Which is that Mom has won the Power Bool and is on a cruise with Loch Ness (not true) so he has to watch you next Friday. Act like you are NOT happy about it!"
        },
        rules: [
            {
                trigger: "If you hear any mention of a spell...",
                action: "Exclaim \"Spell?! What spell??\" and laugh nervously as you change the subject by asking the person who said it to be in your TicTok. If they say yes ask them what they think of your DJ Name (Dräc), explain that you'll be able to go by Dracula once he dies, and share your frustration with living in his shadow."
            },
            {
                trigger: "If the wolfman tells you their secret...",
                action: `Wait until the Wolfman is near Van Helsing and show them this cool TikTok of the Full Moon to trigger them to attack Van Helsing and save your dad (for yourself!)`,
                link: "https://www.tiktok.com/@rami_astro/video/7065432836064431407?is_from_webapp=v1&item_id=7065432836064431407"
            },
            {
                trigger: "If Boris announces that there are five minutes left until the Monster Mash...",
                action: "Find him in the hall to discuss the specifics of the spell and get the stake from him (careful not to touch it!)"
            }
        ]
    },
    {
        name: "Witchess Aloucard",
        pw: "2143168682",
        bio: `A witch. The real kind. None of that Harry Potter bullshit. Also Dracula's Ex… DRAMA!`,
        background: `It's the first Monster Mash since you and Dracula broke up, and ding-dong, the witch is here for a wicked good time! If only you could bring yourself to kill Dracula, but old flames y'know. You did, however, cast a spell that may end Dracula's life quicker than he ended your romance (via text!). You let the details of the spell slip to your new lover, Boris who promised to keep it secret and thinks it could change monster music forever…`,
        clues: [`Cast the spell that will grant whoever kills Dracula at the Monster Mash some secrect special power…`, `Dracula broke her heart… over TEXT!`, `Was never able to get Dracula to turn her into a vampire`, `Is very distressed by Dracula's death and has come to his defense in the past.`],
        message: {
            to: `5083693029`,
            content: `that you can't wait to mash your face off! Also, on a totally unrelated note, ask if they happen to know anything about a monster mash spell someone MAY have cast last year? You're feeling kinda guilty about the Dracula spell and hope news hasn't gotten out.`
        },
        rules: [{
            trigger: `If you see someone with a beer in their hand…`,
            action: `Shout WITCH'S BREWSKI! Fist bump them and everyone around then say “Time to reload myself!”, before heading to the lab to get yourself &/or others another Witch's brew… the 3rd time this happens you're feeling yourself & start beatboxing for your people.`
        },
        {
            trigger: `If you have a conversation with Vito…`,
            action: `Try and play this one super cool.. you aren't really welcome in the Vampire community since the break-up, but DAMN would you love Dracula powers. Let Vito know that Dracula would not turn you when you were dating, but if he were to turn you into a vampire you would repay him with “magical favors” ;) ;)`
        },
        {
            trigger: `If the people around you are shit talking Dracula…`,
            action: `If Dracula is not there, you join in on it, FUCK that Vamp, they broke up with you over text… If Dracula IS there, you feel weirdly conflicted and end up defending them. You then must show the group a cute old photo of you and Dracula that “just came up on your phone” - so weird!!`
        }]
    },
    {
        name: `Wolfman`,
        pw: `4043680897`,
        bio: `The only thing scarier than this bad boy is coming face to face with him under a full moon`,
        background: `You're basically the sickest werewolf around, while the other werewolves spend their nights chasing cars and eating people, you prefer the cool jazz nights Igor hosts at The Graveyard. You disdain violence and pride yourself in your 12-year no-bite streak which was almost broken last weekend when Van Helsing nearly killed you before forcing you to invite them to the monster mash so they can kill Dracula. Problem is, it's a full moon that night…`,
        clues: [`HATES vampires for coining the term "Wolfman"`, `Bloodlusted due to the full moon`, `Invited Van Helsing and lied about being his cousin…`, `Only attacks when provoked / triggered (TikTok!)`],
        message: {
            to: `4155336938`,
            content: `letting them know the details of the event. Make them promise to play it cool and tell everyone there that you invited them because you are cousins (not really, but a solid cover right?) and NOT to tell anyone that they intimidated you into inviting them.`
        },
        rules: [{
            trigger: `If someone refers to you as The Wolfman…`,
            action: `Let them know that you prefer your human name, Frankie. In fact you find that name really derogatory and HATE that Dracula started calling you that. If they're still listening, go on and on about monster naming edicate and how monster should choose their own names.`
        },
        {
            trigger: `If you hear mention of Vampires…`,
            action: `Slowly began to growl and bark as the age old feud between Vampire and Werewolf kind always causes your blood to boil. If Vito is mentioned, you think of your own father and are compelled to go tell Vito that Van Helsing is gonna kill his Dad & isn't really your cousin… but keep it secrect!`
        },
        {
            trigger: `If you see the full moon…`,
            action: `If you see 1 full moon, let out a long & classic Wolfman howwwlllll!!! Get people to play along like you're the fucking wolf MAN for Devil's sake…. BUT if you see 3 full moon's at the same time… you remember how Van Helsing did you wrong and BITE THEIR ARM!`
        }]

    },
    {
        name: "Zombo the Zomb",
        pw: "7036777754",
        bio: `Dracula's new girlfriend and by far the most underrated member of the CK5`,
        background: `You're an immensely talented artist, but for now that doesn't matter because you are dating THE Dracula. Too bad you think someone may have put a hit on your BF. To be on the safe side, you hired someone to investigate. Good news, though, you and the CK5 were FINALLY asked to play the Monster Mash! Hope Dracula doesn't bring his stupid kid to the show (he sucks)…`,
        clues: [`Hates dracula's son/wife & is very drunk - might have taken some of Old Gregg's drugs`, `Would do anything to get Boris to represent her since The Devil does not recognise their tallents - including taking a stake to the heart.`, `Knew about the hit and hired the detective.`, `Loves Dracula`],
        message: {
            to: `5303071303`,
            content: `that you're so excited that she is your new Zombie sister and you cannot WAIT to show her the ropes. You don't want to brag… but you're dating Dracula and you might be able to introduce the two of them at the Monster Mash`
        },
        rules: [
            {
                trigger: `If “Caretaker Dan” (AKA Detective Dan) comes up to speak…`,
                action: `Find somewhere quiet and explain that a while back you overheard Davey Jones talking about a hit on your lover Dracula and that you hired Dan to figure out who it was that placed the hit and prevent it from happening. You suspect The Devil, but who knows… now you're so nervous you need a drink.`
            },
            {
                trigger: `If you see Vito (aka Dräc) making TikToks…`,
                action: `How dare he show up here… Why isn't he with his stupid mother?? Let him know that he will NEVER be his father and he should *hiccup* just stop trying… then burst into tears… ok you may have had too much to drink. Whoops!`
            },
            {
                trigger: `If Boris gives you or the CK5 any feedback…`,
                action: `Take it to heart… meaning literally stab the things he gives you into your heart. Boris LOVES spooky shit like that. Make sure Boris sees you do it to. He will surely favor you now! *hiccup*`
            }
        ]

    }
]