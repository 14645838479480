const HARDCODED_BUCKET_LIST = [
    "Get married to the most beautiful woman on the face of the earth",
    "Go skydiving",
    "Stare down a grizzly bear in the wild",
    "Do a Barrel Roll in a Plane",
    "Do a standing backflip",
    "Paint a Portrait of my Wife",
    "Give a Televised Speech",
    "Be a Father",
    "Catch a Shark",
    "Learn how to surf",
    "Paint my chest to a sporting event",
    "Spend a Night in Every National Park",
    "Drive a Lamborghini Murcielago",
    "Save Someone’s Life",
    "Play Catch with My Child",
    "Win a State Championship",
    "Own a Lake house",
    "Own a Beach house",
    "Be on top of the tallest building in the world",
    "Be in Niagara Falls",
    "Paraglide Over the Grand Canyon",
    "Own a Pet Monkey",
    "Enjoy a Honeymoon in an Exotic Location",
    "Learn to play 10+ different instruments",
    "Write a Song for Each of my Children and My Wife",
    "Go to Space",
    "Scream something at the top of my lungs on top of a mountain",
    "Snorkel in the Great Barrier Reef",
    "Shake the President’s Hand",
    "Watch Ole’ Faithful",
    "View the Aurora Borealis",
    "Ride a Giant Turtle",
    "Have a Sandwich Named After Me",
    "Ride a Bull",
    "Get my Pilot’s License",
    "Be in a room filled with balloons",
    "Deliver a Knockout Punch",
    "Live Where English is Not the Primary Language",
    "Grow my beard out for a full year",
    "See the Harlem Globetrotters",
    "Be on Forbes List",
    "Hot wire a car",
    "Ski Barefooted",
    "Live out a Man vs. Wild episode",
    "Spend a Night in Jail",
    "Work on an archaeological dig",
    "Bang on a 360˚ drum set",
    "Bungee Jump Somewhere Incredible",
    "Live out Ferris Bueller’s Day Off",
    "Be in a Band ",
    "Watch a Movie with the Star from that Movie",
    "Drive Across North America",
    "Do a Stand-up Comedy Routine",
    "Grow my mustache out for a full year",
    "Win and yell “BINGO!” at a Bingo Hall",
    "Break a Guinness Book World Record",
    "Spend the Night in a Haunted House",
    "Wear a Mascot Suit",
    "Go to Sundance",
    "Hold a Giant Check",
    "Give Blood",
    "Kick down a door",
    "Ride a Horse While it Kicks Up and Whinnies",
    "See a Whale in Nature",
    "Chase a Tornado",
    "Release an album",
    "Be the Only Customer in a Theme Park",
    "Ride a Hot Air Balloon at a Ballooning Festival",
    "Complete the Kamasutra",
    "Go Parasailing",
    "Watch a Bullfight",
    "Run with the Bulls",
    "Walk away from a massive explosion",
    "Pet a Penguin",
    "Visit All 7 Continents",
    "Go on an African Safari",
    "Go Ice Fishing",
    "Wrestle Someone in the Coliseum in Rome",
    "Visit Stonehenge",
    "Earn $10,000 at a Casino",
    "Dress up to a Movie Premiere as a Character from that Movie",
    "Spin the Wheel at the Price is Right",
    "Start a Slow Clap at Just the Right Moment",
    "Swim with a Dolphin",
    "Have a Stranger Ask Me for My Autograph",
    "Jump off an Extremely High Dive",
    "Say \"Hi Mom\" on National TV",
    "Own a Plane",
    "Tell a Taxi Cab Driver in New York City to “Step on It” ",
    "Have a Net Worth of $1,000,000",
    "Help Deliver a Baby",
    "Spend a Week in Silence",
    "Sleep Under a Meteor Shower",
    "Drive a Lap around a NASCAR track",
    "Play a Pipe Organ",
    "Go to Washington D.C. during the Cherry Blossom Festival",
    "Learn all the words to “End of the World” by REM",
    "Longboard the streets of San Francisco",
    "Plant a Tree",
    "Live to see 100 years",
    "Have someone play me as a character in a play, tv show, or movie.",
    "Kick a Field Goal",
    "Crowd Surf at a Concert",
    "Give a Stranger a $100 bill",
    "Get a Key to a City",
    "Visit Folsom Prison",
    "Sail on the Ocean",
    "Smash a Guitar After Playing a Song on Stage",
    "Make a Music Video for an Original Song",
    "Make a Half Court Shot",
    "Throw a Surprise Party",
    "Fall in Love",
    "Make $100 street performing",
    "Ride a Dune Buggy through the Desert",
    "Have a Mohawk",
    "Make a Hole in One",
    "Go to the Super Bowl",
    "Win a Talent Show",
    "Dunk a basketball",
    "Swim in a Pool of Jello",
    "Kill a 10+ point buck",
    "Win a Boxing Match",
    "Skinny Dip",
    "Drive to Find a Spotlight",
    "Invent Something",
    "Join the Polar Bear Club",
    "Join the Mile High Club",
    "Be on a “Kiss Cam” at a sporting event",
    "See the Blue Man Group",
    "Get Slimed",
    "Be a Character in a video game (not created)",
    "Be a Bobble Head",
    "Be an Action Figure",
    "Go to San Diego Comic-Con",
    "Play the Veggietales theme song on a Tuba",
    "Go to the Grand ole’ Opry",
    "Go to Disneyland",
    "Enter a Bodybuilding Competition",
    "Be a named character in a movie or TV show set in the MCU",
    "Bowl a Perfect Game",
    "Camp Out on a Beach",
    "Sleep in the Bed of a Truck",
    "Attempt an Acting Career in LA",
    "Read all of the World’s Major Religious Texts",
    "Ride a Jet Ski on the Ocean",
    "Be a Contestant on a Game/Reality TV Show",
    "Be in the Bermuda Triangle",
    "Catch a Foul Ball",
    "Be in the Audience at Saturday Night Live",
    "Get a P.H.D. ",
    "Fly First Class",
    "Have a YouTube video with over 1,000,000 Hits",
    "Bath in a Hot Springs",
    "Drink a Gallon of Milk in an Hour",
    "Give a 7 Day Forecast on Television",
    "Tour the Smithsonian",
    "Buy my Parents a House",
    "Ride my Bike with No Handlebars",
    "Go on a Cross Country Motorcycle Ride",
    "Have My Face on a Box of Wheaties",
    "Play Rugby",
    "Be in a War Reenactment",
    "Be a Superhero",
    "Be on Top of a Moving Train",
    "Win some sort of Eating Contest",
    "Devote a year to community service",
    "Be on a Billboard",
    "Slide down a Pole at a Firehouse",
    "Do an Eskimo Roll in a Kayak",
    "Learn How To Juggle",
    "Go to Portsmouth Kite Festival",
    "Ride a Bicycle Built for Two",
    "Drive on the Autobahn",
    "Play a Set of My Own Music as a DJ ",
    "Attend the Playboy Halloween Party",
    "Shout “I’m the King of the World” on the end of a Ship",
    "Go on a Cruise",
    "Swim in a Public Fountain",
    "Solve a Rubik’s Cube",
    "Receive a Standing Ovation",
    "Run a Marathon",
    "Perform a dance I choreographed",
    "Graduate High School",
    "Own an Expensive Collection of Something",
    "Own a Bar",
    "Watch a Sunset and a Sunrise at the Grand Canyon",
    "Throw a Giant Tailgate Party",
    "Serve Someone at Sonic while Wearing Roller Skates",
    "Spin Pizza Dough in the Air",
    "Pick Out the Lobster I want to Eat at a Restaurant",
    "Be Knighted",
    "Give the Opening Pitch at a Baseball Game",
    "Find Something of Value with a Metal Detector",
    "Jump Off of a Waterfall",
    "Ride a Horse Bareback",
    "Play Ukulele in Hawaii",
    "Own Land",
    "Canoe on the Amazon River",
    "Attend a protest",
    "Have a Gospel Choir Sing at My Funeral",
    "Be in a Flash Mob",
    "Have a Little Brother",
    "Kiss a Girl in Times Square on New Year’s Eve ",
    "Go to a Toga Party",
    "Play Mozart",
    "Go to Carnival",
    "Say a Word of the Day for an Entire Year",
    "Win Something on the Radio",
    "Ride in a Submarine",
    "Be the CEO of a profitable company with 5+ Employees",
    "Do a list item in the buff (36-158)",
    "Ring a Bell for the Salvation Army",
    "Go to Game 7 of the World Series",
    "Get Backstage Passes to a Concert",
    "Ride in the Oscar-Mayer Wienermobile",
    "Attend a Monster Truck Rally",
    "Climb a Water Tower",
    "Go to Burning Man",
    "Create a comic book",
    "Visit Juilliard",
    "Ride an Elephant",
    "Pull a Train Whistle",
    "Spend the Night in a Ghost Town",
    "Beat Super Mario",
    "Throw a Molotov Cocktail",
    "Breathe Fire",
    "Dine and Ditch",
    "Meet Coldplay",
    "Be the Voice of a Cartoon Character",
    "Be a Vegetarian",
    "Be on Sportscenter",
    "Hear a crowd sing back words that I wrote while performing on stage",
    "Make a Kick Ass Website",
    "Ring the Bell at the New York Stock Exchange",
    "Drive 88 mph in a Delorean",
    "Walk Down Sesame Street",
    "Have a House that is Decked Out in Christmas Lights",
    "Drive a Zamboni",
    "Spin a Basketball on my Finger for 1 minute",
    "Have a Cartoon I drew make the Comic strip in a Newspaper",
    "Crash a Parade",
    "Make a Stop Motion Short Film",
    "Meet the Avengers",
    "Touch the Hollywood Sign",
    "Walk the Red Carpet",
    "See a Play on Broadway",
    "See an Opera at the Sydney Opera House",
    "Visit Galapagos Island",
    "Go Wine Tasting in the Napa Valley",
    "See a Kangaroo on Kangaroo Isle",
    "Catch a Fish Noodling",
    "Become an Ordained Minister",
    "Learn How to Drive a Stick Shift",
    "Grow my Hair Out for an Entire Year",
    "Attend Mardi Gras in New Orleans",
    "Go on a Blind Date",
    "Photobomb an A-Lister",
    "Run for an elected office",
    "Play Dungeons and Dragons",
    "Tan on a Nude Beach",
    "Shoot a Flaming Arrow",
    "Have My Name on Something I Donated",
    "See a Total Eclipse",
    "Own a Truck",
    "Teach a Parrot How to Talk",
    "Learn How to Read Music",
    "Visit Mt. Rushmore",
    "Take a Photo-of-the-Day for an Entire Year",
    "Eat Caviar",
    "Eat Escargot",
    "Be a Volunteer Firefighter",
    "Spend 24 hours in Walmart",
    "Ice Skate at the Rockefeller Center",
    "Be Trending on Twitter",
    "Get Stunned by a Taser",
    "Live Homeless for a Month",
    "Smoke a Cuban",
    "Officiate a Wedding",
    "Win a Halloween Costume Contest",
    "Escape from a Desert Isle",
    "Build my Kids their Dream Treehouse",
    "Buy Something off an Infomercial",
    "Celebrate St. Patrick’s Day in Ireland",
    "Hit a Homerun",
    "Kill, cook, and eat an animal without the aid of modern technology",
    "Own a Gun",
    "Own a Boat",
    "Start a Fire by Rubbing Sticks Together",
    "Teach My Kids How to Ride a Bike",
    "Throw a Hand Grenade",
    "Take a Picture Entering All 50 States",
    "Ride a Gondola in Venice",
    "Hallucinate",
    "Pick a Coconut from a Coconut Tree",
    "Live in New York City",
    "Throw a Dart at a Map, Travel Where It Lands",
    "Create a Podcast",
    "Adopt a Dog & Cat",
    "Get Acupuncture",
    "Win a Nobel Peace Prize",
    "Walk across the Golden Gate Bridge",
    "Hang Out with a Celebrity",
    "Get an Old Fashioned Barber Shave",
    "Sing the National Anthem at a Sporting Event",
    "Build and Set Off a Massive Domino Effect ",
    "Ride the London Eye",
    "Go up Inside the Statue of Liberty",
    "Perform Street Magic",
    "Win a Blue Ribbon at a State Fair",
    "Own Season Tickets",
    "Swim On the Equator",
    "Visit ALL 7 wonders of the World",
    "Base jump off of a building",
    "Take Apart, and Rebuild a Car Engine",
    "Crash a Wedding",
    "Touch a Cloud",
    "Drive an Emergency Vehicle with the Sirens On",
    "Be on the Cover of a Magazine",
    "Build a Sundial",
    "Visit Area 51",
    "Be Engulfed in Flames",
    "Go to a Renaissance Festival",
    "Sit in the Oval Office",
    "Learn How to Shuffle Cards",
    "Have a Six Pack",
    "Visit a Nudist Colony",
    "Do a Kip Up",
    "Do a Handstand on the South Pole",
    "Hang Out with the Buried Life guys",
    "Pull a Giant Prank",
    "Run from a Crowd of Screaming Girls",
    "Leave a Buried Treasure to be Discovered",
    "Float Across the Panama Channel",
    "Lean on the Leaning Tower of Pisa",
    "Grant a Wish",
    "Dress up as a Movie Character on Hollywood Blvd.",
    "Create Something Timeless",
    "Roll in King Size Bed of Cash",
    "Streak a Stadium",
    "Prepare and Serve a Michelin Quality Meal",
    "Meet My Doppelganger",
    "Find a Needle in a Haystack",
    "Be in a Riot",
    "Open the 5:00 Local News",
    "Fly a Plane on the Fourth of July",
    "Build an Intricate Rube Goldberg Machine",
    "Cut a Ribbon at a Major Ceremony",
    "Stand Under a Plane While it Lands",
    "Climb Everest",
    "Rock Out with a Legend",
    "Take a Groups of Kids on a Toy Shopping Spree",
    "Experience Zero Gravity",
    "Go Dog Sledding",
    "Ride a Ski Board",
    "Be Dunked with Gatorade After a Huge Win",
    "Bury and Recover Time Capsule",
    "Taxidermy an Animal",
    "Never Say “When” to a Waiter Grating Cheese",
    "Experience an Earthquake",
    "Swim in the Liquid Rainbow",
    "Wear a Dumb and Dumber Tux to a Formal Event",
    "Play the Bagpipes",
    "Be a Grandpa",
    "Go to a High School Reunion",
    "Get a Tattoo",
    "Yes Week",
    "Ride the Ten Best Roller Coasters of My Time",
    "Live in my Dream House",
    "Go to Coachella",
    "Attend the Sky Lantern Festival in Taiwan",
    "Run up Rocky Steps before Dawn",
    "Fly in a Squirrel Suit",
    "Discover a Fallen Meteor",
    "Have a Child Named After Me",
    "Open up a Swiss Bank account",
    "Paint a Graffiti Mural",
    "Milk a Cow",
    "Build a School",
    "Learn How to Fence",
    "Have an Epic Proposal",
    "Score a Touchdown by Diving into the End Zone",
    "Make a GoPro Worthy Video",
    "Spend a Day Giving Away Ice Cream in an Ice Cream Truck",
    "Learn to Say “I Love You” in 50 Different Languages",
    "Have a Banana Pancakes Day",
    "Help a Stranger Cross Something Off of Their Bucket List",
    "Have a Mini-Me for a Day",
    "Earn a Black Belt",
    "Experience a Massive 4th of July Firework War",
    "Drive a Car with Hydraulics",
    "Join the Peace Corps",
    "View the Mona Lisa",
    "Watch a Sunset through the Manhattan Hedge",
    "Ride a Unicycle",
    "Own a Horse",
    "Hitchhike Somewhere Far Away",
    "Learn how to windsurf",
    "Catch a Fish Fly-Fishing",
    "Stick My Head in an Alligator's Mouth",
    "Develop a Defensible Moral Code",
    "Learn how to Yodel",
    "Be an Impressive Break Dancer",
    "Learn to Do the Riverdance",
    "Learn Multiple Popular Dances with my Wife",
    "Have it Rain after Doing a Rain Dance",
    "Cannon Ball into the Neptune Pool",
    "Walk on Fire",
    "Carry the Olympic Torch",
    "Solve the Pineapple Garden Maze",
    "Create a Board Game",
    "Watch a Spaceship Liftoff",
    "See Gorillas in the Wild",
    "Write & Direct a Screenplay",
    "Experience the Sahara",
    "Vacation in Bora Bora",
    "Create a Popular Drinking Game",
    "Kiss the Blarney Stone",
    "Watch a Sumo Wrestling Match in Japan",
    "Be in 4 places at once",
    "Roll One, Smoke One",
    "Attend Oktoberfest",
    "Grow a Bonsai",
    "Learn to Snowboard",
    "Outbid Someone in a Live Auction",
    "Write a Set of Letters to give to My Children after I die",
    "Win a Bet on a Horse at the Kentucky Derby",
    "Camp Out at a Music Festival",
    "See the Loch Ness Monster",
    "Be a Juror",
    "Live Off the Land for a year",
    "Attend a Murder Mystery Dinner",
    "Celebrate Midsummer in Sweden",
    "Keep a Journal for an Entire Year",
    "Climb a Sequoia",
    "Steal 2nd Base at a Major League Stadium",
    "Reminisce with my HS football team FNL Style",
    "Smash a Watermelon with Gallagher",
    "Swim with a Shark",
    "Kick it with Royalty",
    "Ride an Airboat Through the Everglades",
    "Escape Alcatraz",
    "Learn How to Pick a Lock",
    "Take a Miss America Pageant Contestant on a Date  ",
    "Study Abroad",
    "Go Geocaching",
    "Go Zorbing on the Ocean",
    "Find Gold",
    "Play Golf at an Exclusive Course",
    "Sail through the Caribbean Pirate Style",
    "Live with an Isolated Culture",
    "Have My Own Page on Wikipedia",
    "Make/Complete a “Fuck-Kissed” List with my Wife",
    "Drive the Batmobile",
    "Be Surprised by a Conversation with a Chatbot I Created",
    "Walk My Child Down the Aisle",
    "Sit on Abraham Lincoln’s lap",
    "Experience the World’s Largest Food Fight",
    "Forget a Weekend in Vegas",
    "Experience My Heritage",
    "Take a Short Video Everyday for an Entire Year",
    "Surf a Tube",
    "Eat a Honeycomb from a Beehive",
    "Trade for Something Worth $1k in the Red Paperclip Challenge",
    "Make Moonshine",
    "Work Out on Muscle Beach",
    "Be a National Champion",
    "Own a Rolex",
    "See the Eye of a Hurricane or Tornado",
    "Perform a Spoken Word at a Coffee Shop",
    "Experience 24 hours of Sunlight and 24 hours of Darkness",
    "Win a Staring Contest with a London Guard",
    "Swim in a Glow Worm River",
    "Experience an Active Volcano",
    "Make a Quality Piece of Furniture",
    "Own an Aquarium Filled with Exotic Fish",
    "Lead a Second Line",
    "Build a House of Cards with a Full Deck",
    "Back Float in a Cranberry Pond",
    "Sleep in an Igloo I Built",
    "Read 500+ Books",
    "Shoot Lightning from my Fingertips",
    "Watch all of the AFI 100 movies",
    "Meditate with Buddhist Monks",
    "Make Up for the Worst Things I’ve done",
    "Kiss a Movie Star",
    "Confess to a Catholic Priest",
    "Squash Grapes with My Toes",
    "Learn and perform an opera piece",
    "Win Big at a Shady Underground Gambling Event",
    "Sing in a Southern Baptist Choir",
    "Travel with the Circus",
    "Compete in the Red Bull Flugtag",
    "Have an App I created Sold in the App Store",
    "Give a TED talk",
    "Have a Beard of Bees",
    "Make a Muppet",
    "French kiss a French woman ",
    "Create a Family Crest or Sigil",
    "Start Pay it Forward",
    "Live the Good Life"
]
export default HARDCODED_BUCKET_LIST